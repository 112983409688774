import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { Hero, Section, Box, Button } from '../components/bulma';
import Content, { HTMLContent } from '../components/Content';

import DigestSection from '../components/DigestSection';

export const BlogPostTemplate = ({ content, contentComponent, description, tags, title, helmet }) => {
	const PostContent = contentComponent || Content;

	return (
		<section className="section">
			{helmet || ''}
			<Hero color="dark">
				<br />
				<h1 className="title is-size-2 has-text-weight-bold is-bold-light">{title.replace('&nbsp;', ' ')}</h1>
				<p>{description}</p>
			</Hero>
			<div className="container content">
				<br />

				<Section>
					<PostContent content={content} />
				</Section>

				{tags && tags.length ? (
					<div style={{ marginTop: `4rem` }}>
						<h4>Tags</h4>
						<ul className="taglist">
							{tags.map((tag) => (
								<li key={tag + `tag`}>
									<Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
								</li>
							))}
						</ul>
					</div>
				) : null}
			</div>
			<Section>
				<h2 className="title is-size-2 has-text-centered">Other FinTech Healines</h2>
				<DigestSection box />
			</Section>
		</section>
	);
};

const BlogPost = ({ data }) => {
	const { wordpressPost: post } = data;

	return (
		<Layout>
			<BlogPostTemplate
				content={post.content}
				contentComponent={HTMLContent}
				description={post.description}
				helmet={
					<Helmet titleTemplate="%s | FinTech">
						<title>{`${post.title}`}</title>
						<meta name="description" content={`${post.description}`} />
					</Helmet>
				}
				tags={post.tags}
				title={post.title}
			/>
		</Layout>
	);
};

export default BlogPost;

export const pageQuery = graphql`
	query DigestArticleByID($id: String!) {
		wordpressPost(id: { eq: $id }) {
			id
			title
			content
		}
	}
`;
