import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Column, Columns, Section } from './bulma';

export default function BlogHeadlines({ box }) {
	const data = useStaticQuery(DIGEST_HEADLINES_QUERY);
	const { edges: posts } = data.allWordpressPost;

	return (
		<Columns multiline>
			{posts.map(({ node: post }) => (
				<div key={post.id} className="column">
					<div className="has-text-centered">
						{/* <figure className="image is-128x128">
									{post.featuredimage ? (
										<div className="featured-thumbnail">
											<PreviewCompatibleImage
												imageInfo={{
													image: post.featuredimage,
													alt: `featured image thumbnail for post ${post.title}`
												}}
											/>
										</div>
									) : null}
								</figure> */}
					</div>
					<br />

					<Link className="title is-size-5" to={`/fintech/${post.slug}`}>
						<h3>{post.title.replace('&nbsp;', ' ').replace('&#8211;', ' - ')}</h3>
					</Link>

					<br />
					<br />
					<Link to={`/fintech/${post.slug}`} className="button is-dark">
						Read more >
					</Link>
				</div>
			))}
		</Columns>
	);
}

const DIGEST_HEADLINES_QUERY = graphql`
	query DigestHeadlinesQuery {
		allWordpressPost(filter: { categories: { elemMatch: { name: { eq: "cardql-digest" } } } }) {
			edges {
				node {
					id
					slug
					excerpt
					content
					date
					title
					categories {
						id
						name
					}
				}
			}
		}
	}
`;
